<template>
  <div id="app" class="d-flex align-items-end flex-column">
    <SidebarNavVue v-if="$router.currentRoute.fullPath !== '/login' && $router.currentRoute.fullPath !== '/home' && $router.currentRoute.fullPath !== '/catalogo' && $router.currentRoute.fullPath !== '/sobre' && $router.currentRoute.fullPath !== '/sustentabilidade' && $router.currentRoute.name !== 'area' && $router.currentRoute.fullPath !== '/diferencial'"/>
    <TopBarVue id="topBar" v-if="$router.currentRoute.fullPath !== '/login' && $router.currentRoute.fullPath !== '/home' && $router.currentRoute.fullPath !== '/catalogo' && $router.currentRoute.fullPath !== '/sobre' && $router.currentRoute.fullPath !== '/sustentabilidade' && $router.currentRoute.name !== 'area' && $router.currentRoute.fullPath !== '/diferencial'"/>
    <router-view id="content"></router-view>
    <b-button v-if="$router.currentRoute.fullPath !== '/login' && $router.currentRoute.fullPath !== '/home' && $router.currentRoute.fullPath !== '/catalogo' && $router.currentRoute.fullPath !== '/sobre' && $router.currentRoute.fullPath !== '/sustentabilidade' && $router.currentRoute.name !== 'area' && $router.currentRoute.fullPath !== '/diferencial'" id="SideBarBtn" v-b-toggle.sidebar-no-header>
      <span class="material-symbols-outlined">menu</span>
    </b-button>
  </div>
</template>

<script>
import SidebarNavVue from './components/includes/SidebarNav.vue'
import TopBarVue from './components/includes/TopBar.vue'

export default {
  name: 'App',
  components: {
    SidebarNavVue,
    TopBarVue,
  },
}
</script>

<style>

#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh !important;
  height: auto !important;
}

.current {
  background-color: rgba(128, 128, 128, 0.322);
  border-radius: 10px;
}

.breadcrumb {
  margin: 0 !important;
  height: fit-content;
}

.breadcrumb-item {
  text-decoration: none !important;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

#content {
  width: 80vw;
  margin-right: 2.5rem;
  height: auto !important;
}

.content-data {
  width: 100% !important;
  height: auto!important;
  padding-bottom: 2rem;
}

.badge {
  padding: .3rem .6rem !important;
}

.badge-primary {
  background-color: #013D90;
}

.modal-header .close {
  display: none !important;
}

th {
  text-align: left !important;
  background-color: #013D90 !important;
  color: white !important;
  padding: .5rem 1rem !important;
}

td {
  text-align: left !important;
  padding: 1rem !important;
}

.material-symbols-outlined__action:hover {
  cursor: pointer;
}

.alert {
  width: 15% !important;
  top: 18px !important;
  left: 84% !important;
  transition: .5s ease-in-out !important;
}

#SideBarBtn {
  display: none;
}

.mobile-menu {
  display: none;
}


@media screen and (max-width: 480px) {

  #app {
    width: 100% !important;
    justify-content: center !important;
  }

  #content {
    width: 100vw !important;
    margin: 0 auto !important;
  }

  .header_content__top-bar {
    width: 100vw !important;
    justify-content: end !important;
    padding: 0 !important;
    padding-right: 2rem !important;
  }

  .header_content__top-bar > img {
    display: none;
  }

  .header_content__top-bar  nav {
    display: none !important;
  }

  .header_content__logo img {
    width: 90%;
  }

  .header_content__logo p {
    width: 90%;
    font-size: 1rem !important;
  }

  .mobile-menu {
    display: block !important;
    width: 100vw !important;
    /* height: 35% !important; */
  }

  .mobile-menu a {
    padding: 1rem;
    border: none !important;
  }

  .mobile-menu .active-nav {
    background: #013d9091 !important;
  }

  .carousel_section {
    display: none;
  }

  .areas_section {
    margin-top: 2rem !important;
  }

  .areas_section div:nth-child(2) {
    flex-direction: column;
  }

  .areas_section div.card {
    width: 100% !important;
  }

  .section__coontent {
    flex-direction: column;
  }

  .section__coontent img {
    width: 100% !important;
  }

  .section__coontent div {
    width: 100% !important;
  }

  .section__coontent p {
    width: 100% !important;
  }

  .navbar {
    width: 100vw !important;
  }

  #breadcrumb {
    display: none;
  }

  #page-content {
    width: 100% !important;
    margin: 0 auto;
  }

  .content-data {
    flex-direction: column;
    width: 100% !important
  }

  .user-field {
    margin: 0 auto;
  }

  .card {
    width: 90% !important;
    margin: 0 auto;
  }

  .card__legend {
    width: 60% !important;
  }

  .card__buttons {
    width: 40% !important;
  }

  .comments {
    z-index: 1000;
  }

  /* #topBar {
    margin-top: -6.5rem;
  } */

  #btn-close {
    display: none;
  }

  #SideBarBtn {
    position: fixed;
    display: block;
    bottom: 2rem;
    right: 1rem;

    background: #013D90;
  }

  #SideBarBtn span {
    margin-top: .5rem;
  }

  .info button {

    display: none !important;
  }

  footer {
    padding: 1rem;
    flex-direction: column;
    height: auto !important;
  }

  footer div {
    width: 100% !important;
    margin-top: 2rem;
  }

  footer .footer_area__social {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {

  #navbar-close {
    display: none !important;
  }

  #btn-close {
    display: none !important;
  }

  #content-area {
    width: 85vw;
  }
}

</style>
