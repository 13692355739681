import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './registerServiceWorker'
// import jsCookie from 'js-cookie'

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

let routes;

if (sessionStorage.getItem('routes')) {
  routes = [
    ...JSON.parse(sessionStorage.getItem('routes')).map(route => ({
      path: route.path,
      name: route.name,
      component: () => import(`@/components/${route.component}`),
      // key: route.meta.key,
      meta: route.meta,
    })),
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/components/Profile.vue'),
    },
    { 
      path: '/diferencial', 
      name: 'diferencial',
      component: () => import(`@/components/Differential.vue`),
    },
    { 
      path: '/sustentabilidade', 
      name: 'sustentabilidade',
      component: () => import(`@/components/Sustentability.vue`),
    },
    { 
      path: '/area/:selecionada',
      name: 'area',
      component: () => import(`@/components/Area.vue`),
    },
    { 
      path: '/sobre', 
      name: 'sobre',
      component: () => import(`@/components/About.vue`),
    },
    { 
      path: '/catalogo', 
      name: 'catalogo',
      component: () => import(`@/components/CatalogView.vue`),
    },
    { 
      path: '/home', 
      name: 'home',
      component: () => import(`@/components/Home.vue`),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/components/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    { 
      path: '/', 
      redirect: '/home', 
    },
  ]
} else {

  routes = [
    { 
      path: '/diferencial', 
      name: 'diferencial',
      component: () => import(`@/components/Differential.vue`),
    },
    { 
      path: '/sustentabilidade', 
      name: 'sustentabilidade',
      component: () => import(`@/components/Sustentability.vue`),
    },
    { 
      path: '/area/:selecionada',
      name: 'area',
      component: () => import(`@/components/Area.vue`),
    },
    { 
      path: '/sobre', 
      name: 'sobre',
      component: () => import(`@/components/About.vue`),
    },
    { 
      path: '/catalogo', 
      name: 'catalogo',
      component: () => import(`@/components/CatalogView.vue`),
    },
    { 
      path: '/home', 
      component: () => import(`@/components/Home.vue`),
    },
    { 
      path: '/login', 
      component: () => import(`@/components/Login.vue`),
    },
    { 
      path: '/', 
      redirect: '/home', 
    },
    { 
      path: '*', 
      redirect: '/login', 
    },
  ]
}

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
})

Vue.config.productionTip = false

new Vue({
  router,
  render: function(createElement){
    return createElement(App)
  }
}).$mount('#app')

// if(!jsCookie.get('user')) window.location.href = '/login'

export default router